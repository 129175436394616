import {IWixWindow, IWixAPI, ControllerParams} from '@wix/yoshi-flow-editor'
import {getLinguisticHeaderFromUrl} from '@wix/wix-events-commons-statics'
import {Multilingual} from '../types/state'

export interface MultilingualApi {
  isEnabled: boolean
  currentLanguage: string
  siteLanguages: {
    countryCode?: string
    isPrimaryLanguage: boolean
    locale: string
    languageCode: string
  }[]
}

export const getLinguisticHeader = (controller: ControllerParams['controllerConfig']) => {
  const {
    wixCodeApi: {
      window: {multilingual},
    },
    appParams: {
      instanceId,
      baseUrls: {iframeUrl},
    },
  } = controller

  if (!iframeUrl) {
    return getLinguisticHeaderFromMultilingual(multilingual, instanceId)
  }

  if (iframeUrl) {
    return getLinguisticHeaderFromUrl(iframeUrl)
  }
}

export const getLinguisticHeaderFromMultilingual = (multilingual: MultilingualApi, instanceId: string) => {
  if (multilingual.isEnabled) {
    const language = multilingual.siteLanguages.find(lang => {
      return lang.languageCode === multilingual.currentLanguage
    })

    if (language) {
      return `${language.languageCode}|${language.locale}|${language.isPrimaryLanguage}|${instanceId}`
    }
  }
}

const isPrimaryLanguage = ({multilingual}: IWixWindow): boolean => {
  if (multilingual.isEnabled) {
    const siteLanguage = multilingual.siteLanguages.find(lang => lang.languageCode === multilingual.currentLanguage)
    return siteLanguage?.isPrimaryLanguage ?? false
  }
  return true
}

export const getMultilingualInitialState = (wixCodeApi: IWixAPI): Multilingual => {
  const {currentLanguage, isEnabled} = wixCodeApi.window.multilingual
  return {
    currentLanguage,
    isEnabled,
    isPrimaryLanguage: isPrimaryLanguage(wixCodeApi.window),
  }
}
